import { useRef } from 'react';
import './css/assets.css'

function Block({piece, pos, isGhost, shouldBlink}) {

    return (
      	<div className={`block ${piece} ${isGhost && "ghost"} ${(shouldBlink) && "blink"}`} style={{gridColumn: pos.x, gridRow: [21-pos.y]}}/> //invert y-scale
    );
  }
  
  export default Block;
  